<div class="section_header">
    
    <p class="section_title"><mat-icon class="section_icon">credit_card</mat-icon> Tarjetas</p>
    <mat-form-field class="querySearch" color="primary" appearance="outline">
    <mat-label>Buscar</mat-label>
    <input [formControl]="querySearch" matInput>
    <mat-icon matSuffix>person_search</mat-icon>
  </mat-form-field>
</div>

<div class="tableMaterial" *ngIf="!loader">
    <table mat-table [dataSource]="dataSource" class="table-container">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> <p>{{element.id}}</p> </td>
        </ng-container>
            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef> Número</th>
                <td mat-cell *matCellDef="let element"> {{element.numberCard}} </td>
            </ng-container>
        <ng-container matColumnDef="iban">
            <th mat-header-cell *matHeaderCellDef> IBAN </th>
            <td mat-cell *matCellDef="let element"> {{element.iban}} </td>
        </ng-container>
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> <p>{{ element.category === 0 ? 'Débito' : 'Crédito' }}</p> </td>
        </ng-container>
        <ng-container matColumnDef="issueData">
            <th mat-header-cell *matHeaderCellDef> Fecha de Alta </th>
            <td mat-cell *matCellDef="let element"> {{element.issueData}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef> Apellidos </th>
            <td mat-cell *matCellDef="let element"> {{element.surname1 }} {{element.surname2 }} </td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Situación</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{ 
                'user_verifyOk': element.active === true, 
                'user_verifyKo': element.active === false }"> {{ element.active ? 'Activo' : 'No activo' }} </td>
        </ng-container>
        <ng-container matColumnDef="links">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> <div class="icon-links">
                <div class="icon-link" (click)="getDetail(element.idClient, element.id)">
                    <mat-icon>visibility</mat-icon>
                </div>
                <div class="icon-link" (click)="getUser(element.idClient)">
                    <mat-icon>person</mat-icon>
                </div>
                <div class="icon-link" (click)="getCard(element.idClient)">
                    <mat-icon>format_list_bulleted</mat-icon>
                </div>
            </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;  let i = index"></tr>
    </table>
    <mat-paginator
    (page)="onPageChange($event)"
    [length]="dataSource.data.length"
    [pageIndex]="currentPage -1" 
    [pageSize]="offset"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
    </mat-paginator>
</div>

<div class="skeleton-container" *ngIf="loader" >
    <ngx-skeleton-loader  count="{{offset-1}}" appearance="line" [theme]="{ height: '36px' }">
    </ngx-skeleton-loader>
  </div>
