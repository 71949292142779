import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
/* import { Card, CardCategory } from '../../../models/cards/card'; */
import { CardType } from '../../../models/plans/plans';

@Injectable({
  providedIn: 'root'
})

export class CardsMockService {
/*     public getCardsList(): Observable<Card[]> {

        const card = [
        {
            id: 10,
            name: 'Elisa',
            surname: 'Ruiz Ortega',
            account: '67056676310100000066',
            idClient: '7ce8d0f8-71ae-49d6-aeb1-a7f7c7b68379',
            iban: 'ES6067056676310100000066',
            balance: 1200.00,
            availableBalance: 1180.00,
            numberCard: 4459254305162217,
            active: true,
            issueDate: '03/05/2023',
            expirationDate:'01/04/2025',
            pin: 1122,
            cvv: 233,
            cardType: CardType.VIRTUAL,
            category: CardCategory.DEBIT,
            creditLimit: 0,
            unpaidClientBalance: 0
        },
        {
            id: 11,
            name: 'Elisa',
            surname: 'Ruiz Ortega',
            account: '67056676310100001111',
            idClient: '7ce8d0f8-71ae-49d6-aeb1-a7f7c7b68379',
            iban: 'ES6067056676310100001111',
            balance: 2300.00,
            availableBalance: 2280.00,
            numberCard: 2359254305162217,
            active: true,
            issueDate: '03/05/2023',
            expirationDate:'01/04/2025',
            pin: 2244,
            cvv: 182,
            cardType: CardType.PHYSICAL,
            category: CardCategory.DEBIT,
            creditLimit: 0,
            unpaidClientBalance: 0
        },
        {
            id: 12,
            name: 'Elisa',
            surname: 'Ruiz Ortega',
            account: '67056676310100001111',
            idClient: '7ce8d0f8-71ae-49d6-aeb1-a7f7c7b68379',
            iban: 'ES6067056676310100001111',
            balance: 2300.00,
            availableBalance: 2280.00,
            numberCard: 6099254305162217,
            active: true,
            issueDate: '03/05/2023',
            expirationDate:'01/04/2025',
            pin: 1164,
            cvv: 208,
            cardType: CardType.PHYSICAL,
            category: CardCategory.CREDIT,
            creditLimit: 1000.00,
            unpaidClientBalance: 500.00
        },
        {
            id: 13,
            name: 'Carlos',
            surname: 'Rodríguez Cabrera',
            account: '67056676310100001111',
            idClient: 'fb381098-673d-424a-80b7-5381d56bb238',
            iban: 'ES6067056676310100001111',
            balance: 2600.00,
            availableBalance: 2600.00,
            numberCard: 4533244305162217,
            active: true,
            issueDate: '01/05/2023',
            expirationDate:'01/07/2025',
            pin: 1924,
            cvv: 111,
            cardType: CardType.PHYSICAL,
            category: CardCategory.CREDIT,
            creditLimit: 1000.00,
            unpaidClientBalance: 1000.00
        },
        {
            id: 14,
            name: 'Carlos',
            surname: 'Rodríguez Cabrera',
            account: '67056676310100001111',
            idClient: 'fb381098-673d-424a-80b7-5381d56bb238',
            iban: 'ES6067056676310100001111',
            balance: 2600.00,
            availableBalance: 2600.00,
            numberCard: 2025244305162217,
            active: false,
            issueDate: '01/07/2023',
            expirationDate:'01/09/2025',
            pin: 4243,
            cvv: 987,
            cardType: CardType.VIRTUAL,
            category: CardCategory.CREDIT,
            creditLimit: 1000.00,
            unpaidBalance: 1000.00
        },
        {
            id: 19,
            name: 'Silvia',
            surname: 'Abad García',
            account: '67056676310100001111',
            idClient: '550b7185-79bc-40d5-89a5-2c858d387dd0',
            iban: 'ES6067058876399100001364',
            balance: 4600.00,
            availableBalance: 2600.00,
            numberCard: 2025244305162217,
            active: true,
            issueData: '01/07/2023',
            expirationDate:'14/11/2028',
            pin: 4243,
            cvv: 987,
            cardType: CardType.VIRTUAL,
            category: CardCategory.CREDIT,
            creditLimit: 1000.00,
            unpaidBalance: 1000.00
        },
        {
            id: 32,
            name: 'Silvia',
            surname: 'Abad García',
            account: '67056676310100001111',
            idClient: '550b7185-79bc-40d5-89a5-2c858d387dd0',
            iban: 'ES6067058876399100001364',
            balance: 4600.00,
            availableBalance: 2600.00,
            numberCard: 2025244305162217,
            active: false,
            issueData: '01/07/2023',
            expirationDate:'14/11/2028',
            pin: 4243,
            cvv: 987,
            cardType: CardType.PHYSICAL,
            category: CardCategory.CREDIT,
            creditLimit: 1000.00,
            unpaidBalance: 1000.00
        },
        {
            id: 22,
            name: 'Gisela',
            surname: 'Claure Merida',
            account: '67056676310100001111',
            idClient: 'fb381098-673d-424a-80b7-5381d56bb238',
            iban: 'ES6067058876399100001364',
            balance: 4600.00,
            availableBalance: 2600.00,
            numberCard: 2025244305162217,
            active: false,
            issueData: '01/07/2023',
            expirationDate:'14/11/2028',
            pin: 4243,
            cvv: 987,
            cardType: CardType.PHYSICAL,
            category: CardCategory.CREDIT,
            creditLimit: 1000.00,
            unpaidBalance: 1000.00
        },
        {
            id: 49,
            name: 'Gisela',
            surname: 'Claure Merida',
            account: '67056676310100001111',
            idClient: 'fb381098-673d-424a-80b7-5381d56bb238',
            iban: 'ES6067058876399100001364',
            balance: 4600.00,
            availableBalance: 2600.00,
            numberCard: 2025244305162217,
            active: true,
            issueData: '01/07/2023',
            expirationDate:'14/11/2028',
            pin: 4243,
            cvv: 987,
            cardType: CardType.PHYSICAL,
            category: CardCategory.DEBIT,
            creditLimit: 1000.00,
            unpaidBalance: 1000.00
        },
        {
            id: 24,
            name: 'Gisela',
            surname: 'Claure Merida',
            account: '67056676310100001111',
            idClient: 'fb381098-673d-424a-80b7-5381d56bb238',
            iban: 'ES6067058876399100001364',
            balance: 4600.00,
            availableBalance: 2600.00,
            numberCard: 2025244305162217,
            active: true,
            issueData: '01/07/2023',
            expirationDate:'14/11/2028',
            pin: 4243,
            cvv: 987,
            cardType: CardType.PHYSICAL,
            category: CardCategory.DEBIT,
            creditLimit: 1000.00,
            unpaidBalance: 1000.00
        },
        {
            id: 201,
            name: 'Gisela',
            surname: 'Claure Merida',
            account: '67056676310100001111',
            idClient: 'fb381098-673d-424a-80b7-5381d56bb238',
            iban: 'ES6067058876399100001364',
            balance: 4600.00,
            availableBalance: 2600.00,
            numberCard: 2025244305162217,
            active: true,
            issueData: '01/07/2023',
            expirationDate:'14/11/2028',
            pin: 4243,
            cvv: 987,
            cardType: CardType.PHYSICAL,
            category: CardCategory.DEBIT,
            creditLimit: 1000.00,
            unpaidBalance: 1000.00
        },
        {
            id: 30,
            name: 'Gisela',
            surname: 'Claure Merida',
            account: '67056676310100001111',
            idClient: 'fb381098-673d-424a-80b7-5381d56bb238',
            iban: 'ES6067058876399100001364',
            balance: 4600.00,
            availableBalance: 2600.00,
            numberCard: 2025244305162217,
            active: true,
            issueData: '01/07/2023',
            expirationDate:'14/11/2028',
            pin: 4243,
            cvv: 987,
            cardType: CardType.PHYSICAL,
            category: CardCategory.DEBIT,
            creditLimit: 1000.00,
            unpaidBalance: 1000.00
        },
        

    ]
    return of(card)
       
    } */
}